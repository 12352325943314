import NavDropdown from 'react-bootstrap/NavDropdown';
import {useState, useEffect} from "react";
import {FaHouse, FaA, FaO, FaN, FaD, FaI, FaM, FaP, FaR, FaGears, FaPhotoFilm} from "react-icons/fa6";
import {FaUser, FaUsers, FaMoneyBills, FaMoon, FaSun, FaLock, FaGear} from "react-icons/fa6";
import {GiAlarmClock} from "react-icons/gi";
import {
    Link,
} from "react-router-dom";
import {toggleSideBar} from "../func/Func";
import {NavModal} from "../modals/NavModal";
import {useTheme} from "../hooks/useTheme";
import {useAuth} from "../hooks/useAuth";
import LanguageSwitcher from "../func/LanguageSwitcher";
import {usePermissions} from "../hooks/PermissionContext";
import {FaBell, FaBellSlash, FaClock, FaConciergeBell, FaImage, FaRegBell, FaUserLock} from "react-icons/fa";
import {ChangePasswordModal} from "../modals/ChangePasswordModal";
import {ChangePictureModal} from "../modals/ChangePictureModal";
import {LuAlarmClock} from "react-icons/lu";
import { useLanguageStore } from "../hooks/useLanguage";


export const TopNav = (props) => {
    const permissions = usePermissions();

    const { t } = useLanguageStore();
    const {theme, toggleTheme} = useTheme();
    const [showNavModal, setShowNavModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showChangePicture, setShowChangePicture] = useState(false);
    const {user, signout} = useAuth();
    const [language, setLanguage] = useState('en');

    const handleCloseNavModal = () => setShowNavModal(false);
    const handleShowNavModal = () => setShowNavModal(true);
    const handleShowChangePasswordModal = () => setShowChangePasswordModal(true);
    const handleCloseChangePasswordModal = () => setShowChangePasswordModal(false);
    const handleShowChangePicture = () => setShowChangePicture(true);
    const handleCloseChangePicture = () => setShowChangePicture(false);
    // const logout = () => {
    //     fetch('/apiv2/logout/').then(() => {
    //         window.location = "/login/";
    //     })
    // }
    const accountingUrl = `${window.location.origin}/acc?theme=${theme}`;

    const LinkWrapper = (props) => {
        const {to, children} = props;
        return (
            <Link data-rr-ui-dropdown-item="" to={to} className="dropdown-item">
                {children}
            </Link>
        )
    }

    const MainNavLink = (props) => {
        const {to, children} = props;
        return (
            <Link to={to} className="nav-link">
                {children}
            </Link>
        )
    }

    const PageLogo = () => {
        return (
            <>
                <FaA size={20}/>
                <FaD size={20}/>
                <FaM size={20}/>
                <FaI size={20}/>
                <FaN size={20}/>
                <FaP size={20} color={"var(--bs-primary)"}/>
                <FaR size={20} color={"var(--bs-primary)"}/>
                <FaO size={20} color={"var(--bs-primary)"}/>
            </>
        )
    }

    const changepassword = () => {
        handleShowChangePasswordModal();
    }

    const changepicture = () => {
        handleShowChangePicture();
    }

    return (
        <>
            <NavModal
                show={showNavModal}
                handleCloseNavModal={handleCloseNavModal}
                handleShowNavModal={handleShowNavModal}
                PageLogo={PageLogo}
                theme={theme}
            />
            {showChangePasswordModal && <ChangePasswordModal
                show={showChangePasswordModal}
                handleCloseChangePasswordModal={handleCloseChangePasswordModal}
                handleShowNavModal={handleShowChangePasswordModal}
                theme={theme}
            />}
            {showChangePicture && <ChangePictureModal
                show={showChangePicture}
                handleCloseChangePicture={handleCloseChangePicture}
                handleShowChangePicture={handleShowChangePicture}
                theme={theme}
                user={user}
            />}
            <div className="mb-3">
                <header className="navbar navbar-expand-md d-print-none">
                    <div className="container-xl">
                        {/*<button onClick={toggleSideBar} className="navbar-toggler" type="button"*/}
                        <button onClick={handleShowNavModal} className="navbar-toggler" type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-menu" aria-controls="navbar-menu" aria-expanded="false"
                                aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                            <a href="#">
                                <PageLogo/>
                            </a>
                        </h1>
                        <div className="navbar-nav flex-row order-md-last">
                            <div className="nav-item d-none d-md-flex me-3">
                                <div className="btn-list">
                                </div>
                            </div>
                            <div className="d-none d-md-flex">
                                {theme === 'light' ? (
                                    <a onClick={toggleTheme} href="#" className="nav-link px-0"
                                       data-bs-toggle="tooltip"
                                       data-bs-placement="bottom" aria-label="Enable dark mode"
                                       data-bs-original-title="Enable dark mode">
                                        <FaMoon/>
                                    </a>
                                ) : (
                                    <a onClick={toggleTheme} href="#" className="nav-link px-0"
                                       data-bs-toggle="tooltip" data-bs-placement="bottom"
                                       aria-label="Enable light mode"
                                       data-bs-original-title="Enable light mode">
                                        <FaSun/>
                                    </a>
                                )}
                                <div className="nav-item dropdown d-none d-md-flex me-3">
                                    <a href="#" className="nav-link px-0" data-bs-toggle="dropdown" tabIndex="-1"
                                       aria-label="Show notifications">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                            <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                        </svg>
                                        <span className="badge bg-red"></span>
                                    </a>
                                    <div
                                        className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Last updates</h3>
                                            </div>
                                            <div className="list-group list-group-flush list-group-hoverable">
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto"><span
                                                            className="status-dot status-dot-animated bg-red d-block"></span>
                                                        </div>
                                                        <div className="col text-truncate">
                                                            <a href="#" className="text-body d-block">Example 1</a>
                                                            <div className="d-block text-secondary text-truncate mt-n1">
                                                                Change deprecated html tags to text decoration classes
                                                                (#29604)
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="list-group-item-actions">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon text-muted" width="24" height="24"
                                                                     viewBox="0 0 24 24" strokeWidth="2"
                                                                     stroke="currentColor" fill="none"
                                                                     strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"></path>
                                                                    <path
                                                                        d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto"><span
                                                            className="status-dot d-block"></span></div>
                                                        <div className="col text-truncate">
                                                            <a href="#" className="text-body d-block">Example 2</a>
                                                            <div className="d-block text-secondary text-truncate mt-n1">
                                                                justify-content:between ⇒ justify-content:space-between
                                                                (#29734)
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="list-group-item-actions show">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon text-yellow" width="24" height="24"
                                                                     viewBox="0 0 24 24" strokeWidth="2"
                                                                     stroke="currentColor" fill="none"
                                                                     strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"></path>
                                                                    <path
                                                                        d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto"><span
                                                            className="status-dot d-block"></span></div>
                                                        <div className="col text-truncate">
                                                            <a href="#" className="text-body d-block">Example 3</a>
                                                            <div className="d-block text-secondary text-truncate mt-n1">
                                                                Update change-version.js (#29736)
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="list-group-item-actions">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon text-muted" width="24" height="24"
                                                                     viewBox="0 0 24 24" strokeWidth="2"
                                                                     stroke="currentColor" fill="none"
                                                                     strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"></path>
                                                                    <path
                                                                        d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list-group-item">
                                                    <div className="row align-items-center">
                                                        <div className="col-auto"><span
                                                            className="status-dot status-dot-animated bg-green d-block"></span>
                                                        </div>
                                                        <div className="col text-truncate">
                                                            <a href="#" className="text-body d-block">Example 4</a>
                                                            <div className="d-block text-secondary text-truncate mt-n1">
                                                                Regenerate package-lock.json (#29730)
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a href="#" className="list-group-item-actions">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     className="icon text-muted" width="24" height="24"
                                                                     viewBox="0 0 24 24" strokeWidth="2"
                                                                     stroke="currentColor" fill="none"
                                                                     strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z"
                                                                          fill="none"></path>
                                                                    <path
                                                                        d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                                                                </svg>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <NavDropdown title={(
                                <>
                                    <span href="#" className="nav-link d-flex lh-1 text-reset p-0"
                                          data-bs-toggle="dropdown"
                                          aria-label="Open user menu">
                                        {user?.picture ? (<div>
                                                <img src={user?.picture} className="avatar avatar-sm"/>

                                            </div>
                                        ) : (
                                            <FaUser className="avatar avatar-sm"/>)}
                                        {/*<span className="avatar avatar-sm"*/}
                                        {/*      style={{backgroundImage: 'url(./static/avatars/003f.jpg)'}}></span>*/}
                                        <div className="d-none d-xl-block ps-2">
                                            <div>{user?.username}</div>
                                            {/*<div className="mt-1 small text-secondary">Tax Accountant</div>*/}
                                        </div>
                                    </span>
                                </>
                            )} id="navbarScrollingDropdown">
                                {/*<NavDropdown.Item>*/}
                                {/*    Action*/}
                                {/*</NavDropdown.Item>*/}
                                {/*<NavDropdown.Item>*/}
                                {/*    Another action*/}
                                {/*</NavDropdown.Item>*/}
                                <NavDropdown.Item onClick={signout}>
                                    <FaLock className={"me-2"}/>
                                    {t('Logout')}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={changepassword}>
                                    <FaUserLock className={"me-2"}/>
                                    {t("Change Password")}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={changepicture}>
                                    <FaImage className={"me-2"}/>
                                    {user?.picture ? t("Change Picture") : t("Add Picture")}
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={toggleTheme}>
                                    {theme === 'light' ? (<FaMoon className={"me-2"}/>) : (<FaSun className={"me-2"}/>)}
                                    {theme === 'light' ? t("Dark Mode") : t("Light Mode")}
                                </NavDropdown.Item>
                            </NavDropdown>


                        </div>
                    </div>
                </header>
                <header className="navbar-expand-md">
                    <div className="collapse navbar-collapse" id="navbar-menu">
                        <div className="navbar">
                            <div className="container-xl">
                                <div className="row flex-fill align-items-center">
                                    <div className="col">
                                        <ul className="navbar-nav">
                                            <LanguageSwitcher theme={theme} language={language} setLanguage={setLanguage}/>
                                            <MainNavLink to={"/"}>
                                                <FaHouse/>
                                                <span className="ms-1">{t('Home')}</span>
                                            </MainNavLink>

                                            <NavDropdown title={(

                                                <>

                                                    <FaMoneyBills/>
                                                    <span className="ms-1">{t('Finance')}</span>
                                                </>
                                            )} id="navbarScrollingDropdown">
                                                <LinkWrapper to={"/finance/accounts"}>{t('Accounts')}</LinkWrapper>
                                                {/*divider*/}
                                                <LinkWrapper to={"/finance/expenses"}>{t('Expense Setup')}</LinkWrapper>
                                                <LinkWrapper
                                                    to={"/finance/income-setup"}>{t('Income Setup')}</LinkWrapper>
                                                {permissions?.permissions?.includes("view_transctn") &&
                                                    <LinkWrapper
                                                    to={"/finance/bankstatement"}>{t('Process Bankstatement')}
                                                    </LinkWrapper>}
                                                 {permissions?.permissions?.includes("view_transctn") &&
                                                   <LinkWrapper
                                                    to={"/finance/reports"}>{t('Reports')}
                                                    </LinkWrapper>}
                                            </NavDropdown>
                                            <NavDropdown title={(

                                                <>

                                                    <FaMoneyBills/>
                                                    <span className="ms-1">{t('Accounting')}</span>
                                                </>
                                            )} id="navbarScrollingDropdown">
                                                <LinkWrapper to={"/accounting"}>{t('Home')}</LinkWrapper>
                                                <a href={accountingUrl} target="_blank"
                                                   rel="noopener noreferrer"
                                                className="ms-1">{t('Home')}</a>
                                                {/*divider*/}
                                            </NavDropdown>
                                            {permissions?.permissions?.includes("view_members") &&
                                                <MainNavLink to={"/members"}>
                                                    <FaUsers/>
                                                    <span className="ms-1">{t('Members')}</span>
                                                </MainNavLink>}
                                            <MainNavLink to={"/settings"}>
                                                <FaGear/>
                                                <span className="ms-1">{t('Database Settings')}</span>
                                            </MainNavLink>

                                            <NavDropdown title={(
                                                <>
                                                    <FaGears/>
                                                    <span className="ms-1">{t('Other Settings')}</span>
                                                </>
                                            )} id="navbarScrollingDropdown">
                                                <LinkWrapper to={"/other_settings/company"}>
                                                    {t('Company')}
                                                </LinkWrapper>

                                                {permissions?.permissions?.includes("view_groups") &&
                                                    <LinkWrapper to={"/other_settings/groups"}>
                                                        {t('Groups')}
                                                    </LinkWrapper>}
                                                <LinkWrapper
                                                    to={"/other_settings/usermanagement"}>{t('User Management')}
                                                </LinkWrapper>
                                                {/*divider*/}
                                            </NavDropdown>
                                            <NavDropdown title={(
                                                <>
                                                    <LuAlarmClock/>
                                                    <span className="ms-1">{t('Reminders')}</span>
                                                </>
                                            )} id="navbarScrollingDropdown">
                                                {permissions?.permissions?.includes("view_members") &&
                                                    <LinkWrapper to={"/reminders/birthdays"}>
                                                        {t('Upcoming Birthdays')}
                                                    </LinkWrapper>}
                                                <LinkWrapper
                                                    to={"/reminders/events"}>{t('Upcoming Events')}</LinkWrapper>
                                                {/*divider*/}
                                            </NavDropdown>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>
        </>
    )
}