import { createContext, useContext, useMemo,useEffect } from "react";
import {useLocalStorage} from "../components/useLocalStorage";
import '@progress/kendo-theme-bootstrap/dist/all.css';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

  const [theme, setTheme] = useLocalStorage("theme", null);

    useEffect(() => {
    if (theme === null) {
      setTheme('light');
      document.body.setAttribute('data-bs-theme', 'light');
      loadKendoTheme('light');
    } else {
      document.body.setAttribute('data-bs-theme', theme);
      loadKendoTheme(theme);
    }
  }, [theme]);


  const loadKendoTheme = (theme) => {
    const existingLink = document.getElementById('kendo-theme');
    if (existingLink) {
      existingLink.href = theme === 'light'
        ? '@progress/kendo-theme-bootstrap/dist/bootstrap-4.scss'
        : '@progress/kendo-theme-bootstrap/dist/bootstrap-4-dark.scss';
    } else {
      const link = document.createElement('link');
      link.id = 'kendo-theme';
      link.rel = 'stylesheet';
      link.href = theme === 'light'
        ? '@progress/kendo-theme-bootstrap/dist/bootstrap-4.scss'
        : '@progress/kendo-theme-bootstrap/dist/bootstrap-4-dark.scss';
      document.head.appendChild(link);
    }
  };
  // if(theme === null){
  //   setTheme('light');
  //   document.body.setAttribute('data-bs-theme', 'light');
  // }else{
  //   document.body.setAttribute('data-bs-theme', theme);
  // }


  const toggleTheme = () => {
    let newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    document.body.setAttribute('data-bs-theme', newTheme);
    loadKendoTheme(newTheme);
  };

  const value = useMemo(
    () => ({
      toggleTheme,
      theme,
    }),
    [theme]
  );

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  return useContext(ThemeContext);
};